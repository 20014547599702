/*-----------------------------------------------------
VARIABLES
------------------------------------------------------*/
/* Fonts */
$bodyfont                                 : 'Titillium Web', sans-serif;
$headingfont                              : 'Titillium Web', sans-serif;
$stylishfont                              : 'Calafia-Regular';

/* Theme Colors */
$bodycolor                                : darken(white, 1%);
$bodytext                                 : darken(#8d97ad, 10%);
$themecolor                               : #ED1C24;
$themecolor-alt                           : #26c6da;
$themecolor-dark                          : $bodytext;
$headingtext                              : darken(#8d97ad, 20%);

/* bootstrap Color */
$danger                                   : #ff4d7e;
$success                                  : #2cdd9b;
$warning                                  : #fec500;
$primary                                  : #316ce8;
$info                                     : #188ef4;
$inverse                                  : #3e4555;
$muted                                    : #8d97ad;
$dark                                     : #263238;
$light                                    : #f5f7fa;
$extra-light                              : #ebf3f5;
$secondary                                : #727b84;
$facebook                                 : #3b5a9a;
$twitter                                  : #56adf2;

/*Light colors*/
$light-danger                             : #f9e7eb;
$light-success                            : #e8fdeb;
$light-warning                            : #fff8ec;
$light-primary                            : #f1effd;
$light-info                               : #cfecfe;
$light-inverse                            : #f6f6f6;
$light-megna                              : #e0f2f4;

/*Dark Colors*/
$danger-dark                              : #d73e6b;
$success-dark                             : #1dc8cd;
$warning-dark                             : #dcaf17;
$primary-dark                             : #345bcb;
$info-dark                                : #2c73cb;
$red-dark                                 : #d61f1f;
$inverse-dark                             : #232a37;
$dark-transparent                         : rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white                                    : #ffffff;
$red                                      : #fb3a3a;
$yellow                                   : #fed700;
$purple                                   : #7460ee;
$blue                                     : #02bec9;
$megna                                    : #1dc8cd;
$orange                                   : #ff6a5b;

/*Extra Variable*/
$rgt                                      : right;
$lft                                      : left;
$border                                   : rgba(120, 130, 140, 0.13);
$radius                                   : 4px;
$card-shadow                              : 0 0 30px rgba(115, 128, 157, 0.10);
$header-shadow                            : 0 0 30px rgba(115, 128, 157, 0.20);
$img-shadow                               : 0 0 30px rgba(115, 128, 157, 0.3);